import styles from './styles.module.css';
import classNames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { ComponentType, CSSProperties, ReactNode } from 'react';
import { CaretIcon } from 'src/icons/caret';

interface Props {
	icon: ComponentType<any>;
	title: string;
	linkTo?: string;
	externalLink?: string;
	linkText: string;
	className?: string;
	style?: CSSProperties;
	children?: ReactNode;
}

export const MoreOnLink = (props: Props) => {
	const { title, linkTo, externalLink, linkText, style, children, className } = props;
	const IconElement = props.icon;
	const wrapperClass = classNames(styles.wrapper, className);

	const getLink = () => {
		if (externalLink) {
			return (
				<a className={styles.customLink} href={externalLink || '/'}>
					{linkText} <CaretIcon direction="right" />
				</a>
			);
		} else {
			return (
				<AnchorLink to={linkTo || '/'} className={styles.customLink}>
					{linkText} <CaretIcon direction="right" />
				</AnchorLink>
			);
		}
	};

	return (
		<div className={wrapperClass} style={style}>
			<div className={styles.icon}>
				<IconElement />
			</div>
			<h5 className={styles.title}>{title}</h5>
			<div className={styles.content}>{children}</div>
			<div className={styles.linkContainer}>{getLink()}</div>
		</div>
	);
};
