import styles from './styles.module.css';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { ContentThumbnailCard } from 'src/components/contentThumbnailCard';
import { CustomButton } from 'src/components/formComponents/customButton';
import { Gallery } from 'src/components/gallery';
import { MainImage } from 'src/components/mainImage';
import { MoreOnLink } from 'src/components/moreOnLink';
import { MultiCarousel } from 'src/components/multiCarousel';
import { ReadMore } from 'src/components/readMore';
import * as Icons from 'src/icons';
import { LinkHelper } from 'src/utils/link-helper';
import {
	ContentfulArticleResource,
	ContentfulHomePage,
	ContentfulProjectResource,
	ContentfulPublicationResource,
	ContentfulReportResource,
	ContentfulVideoResource,
	Maybe,
	ContentfulHomePageServiceSectionItems,
	AllResourcesGroup,
} from 'src/graphql-types';

import {
	SectionContainer,
	SectionContainerFlex,
	SectionContainerTitle,
} from 'src/components/sectionContainer';
import {
	PageNames,
	FilterContentTypeItems,
	ContentfulTypes,
	getHrImageUrl,
} from 'src/utils/common';
import { LinkState } from 'src/utils/search-manager';
import {
	defaultRichTextOptions,
	getRichText,
} from 'src/templates/utils/richTextOptions/defaultOptions';
import { curatedTitle } from 'src/utils/curated-title';
import { DefaultLayoutTemplate } from 'src/pages/layout';

type AnyResource =
	| ContentfulProjectResource
	| ContentfulPublicationResource
	| ContentfulVideoResource
	| ContentfulArticleResource
	| ContentfulReportResource;

interface PageContext {
	pageContext: {
		homePage: ContentfulHomePage;
		allContentfulProjectResource?: {
			nodes: ContentfulProjectResource[];
		};
		allContentfulPublicationResource?: {
			nodes: ContentfulPublicationResource[];
		};
		allContentfulVideoResource?: {
			nodes: ContentfulVideoResource[];
		};
		allContentfulArticleResource?: {
			nodes: ContentfulArticleResource[];
		};
		allContentfulReportResource?: {
			nodes: ContentfulReportResource[];
		};
	};
}

const getMoreOnLinks = (
	nodes: Maybe<ContentfulHomePageServiceSectionItems>[] | null | undefined
) => {
	return nodes
		?.filter((node) => node)
		.map((node: Maybe<ContentfulHomePageServiceSectionItems>) => {
			// Look up the icon as needed. If we don't find the icon by name, then
			// just don't show an icon at all.
			const icon = Icons[node?.icon as keyof typeof Icons] || undefined;
			const linkTo = node?.linkToPage
				? `/${LinkHelper.getPageBaseUrl(node.linkToPage)}`
				: undefined;

			return (
				<MoreOnLink
					key={node?.contentful_id || ''}
					icon={icon}
					title={node?.title || ''}
					linkText={node?.linkText || ''}
					linkTo={linkTo}
					externalLink={node?.linkUrl || undefined}
					className={styles.moreOnLink}
				>
					{getRichText(node?.description, defaultRichTextOptions)}
				</MoreOnLink>
			);
		});
};

const TopSelectiveMultiCarousel = (props: PageContext) => {
	enum ResourceType {
		ResearchProject = 1,
		PublicationReport,
		Media,
		Other,
	}
	const [activeResourceTypes, setActiveResourceTypes] = useState(
		new Set<ResourceType>().add(ResourceType.ResearchProject)
	);
	const [resources, setResources] = useState<AnyResource[]>([]);

	const {
		featuredResearchProjects,
		featuredPublications,
		featuredMedia,
	} = props.pageContext.homePage;
	const {
		allContentfulProjectResource,
		allContentfulPublicationResource,
		allContentfulVideoResource,
		allContentfulArticleResource,
		allContentfulReportResource,
	} = props.pageContext;

	const getResourceType = (typeStr: string | null | undefined) => {
		switch (typeStr) {
			case ContentfulTypes.Project:
				return ResourceType.ResearchProject;
			case ContentfulTypes.Publication:
			case ContentfulTypes.Report:
				return ResourceType.PublicationReport;
			case ContentfulTypes.Video:
			case ContentfulTypes.Article:
				return ResourceType.Media;
			default:
				return ResourceType.Other;
		}
	};

	useEffect(() => {
		const sortResource = (resource1: AllResourcesGroup, resource2: AllResourcesGroup) => {
			const r1SortField =
				(resource1 as any)?.publicationDate ||
				(resource1 as ContentfulProjectResource)?.startDate ||
				resource1?.updatedAt ||
				'';
			const r2SortField =
				(resource2 as any)?.publicationDate ||
				(resource2 as ContentfulProjectResource)?.startDate ||
				resource2?.updatedAt ||
				'';
			// sorts in reverse: most recent to oldest.
			// date is expected to be YYYY-MM-DD, so string sort is fine
			return r1SortField < r2SortField ? 1 : r1SortField > r2SortField ? -1 : 0;
		};
		const projects = featuredResearchProjects ? (featuredResearchProjects as AnyResource[]) : [];
		const publications = featuredPublications ? (featuredPublications as AnyResource[]) : [];
		const media = featuredMedia ? (featuredMedia as AnyResource[]) : [];
		const featuredResources = [...projects, ...publications, ...media];
		const latestResources: AllResourcesGroup[] = [];
		const resourceIdsSet = new Set(
			featuredResources
				.filter((r) => r)
				.map((r) => r.contentful_id)
				.filter((id) => id)
		);
		const addToResources = (resourceArr: AnyResource[]) => {
			resourceArr.map((r) => {
				if (!resourceIdsSet.has(r?.contentful_id)) {
					latestResources.push(r);
				}
			});
		};
		addToResources(allContentfulProjectResource?.nodes || []);
		addToResources(allContentfulPublicationResource?.nodes || []);
		addToResources(allContentfulVideoResource?.nodes || []);
		addToResources(allContentfulArticleResource?.nodes || []);
		addToResources(allContentfulReportResource?.nodes || []);
		setResources([...featuredResources.sort(sortResource), ...latestResources.sort(sortResource)]);
	}, [props]);

	const onResourcePillClick = (resource: ResourceType) => {
		setActiveResourceTypes((prevResources) => {
			const newSet = new Set(prevResources);
			if (prevResources.has(resource)) {
				newSet.delete(resource);
			} else {
				newSet.add(resource);
			}
			return newSet;
		});
	};

	return (
		<>
			<SectionContainer>
				<SectionContainerFlex
					style={{
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}
					noSpaceBetween
				>
					<h6 className={styles.seeOurLatest}>SEE OUR LATEST</h6>
					<CustomButton
						content="Research Projects"
						variant={activeResourceTypes.has(ResourceType.ResearchProject) ? 'primary' : undefined}
						onClick={() => onResourcePillClick(ResourceType.ResearchProject)}
						inlineStyle={{ marginRight: '10px' }}
					/>
					<CustomButton
						content="Publication"
						variant={
							activeResourceTypes.has(ResourceType.PublicationReport) ? 'primary' : undefined
						}
						onClick={() => onResourcePillClick(ResourceType.PublicationReport)}
						inlineStyle={{ margin: '5px' }}
					/>
					<CustomButton
						content="Media"
						variant={activeResourceTypes.has(ResourceType.Media) ? 'primary' : undefined}
						onClick={() => onResourcePillClick(ResourceType.Media)}
						inlineStyle={{ margin: '5px' }}
					/>
				</SectionContainerFlex>
			</SectionContainer>
			<SectionContainer withBorderTop>
				<MultiCarousel className={styles.multiCarousel}>
					{resources
						.filter((resource) => {
							return (
								resource &&
								activeResourceTypes.has(getResourceType(resource?.internal?.type)) &&
								resource.thumbnailLink &&
								(resource.thumbnailLink?.thumbnailImage || resource.thumbnailLink?.thumbnailIcon)
							);
						})
						.map((resource) => {
							const style = {
								height:
									'158px' /* this is the same height as .multiCarousel > img in related CSS file */,
								paddingTop: 'unset',
							};
							const icon = resource.thumbnailLink?.thumbnailIcon
								? {
										Icon:
											Icons[resource.thumbnailLink.thumbnailIcon as keyof typeof Icons] ||
											undefined,
										style: {
											...style,
											maxWidth: '100%',
										},
								  }
								: undefined;
							const image = {
								src: resource.thumbnailLink?.thumbnailImage?.fluid?.src,
								style,
							};
							const linkTo =
								(resource as ContentfulPublicationResource).externalPublicationUrl ||
								(resource as ContentfulArticleResource).externalArticleUrl
									? undefined
									: LinkHelper.linkTo(resource);
							const externalLink =
								(resource as ContentfulPublicationResource).externalPublicationUrl ||
								(resource as ContentfulArticleResource).externalArticleUrl;
							return (
								<ReadMore
									key={resource.contentful_id || ''}
									image={image}
									icon={icon}
									title={{
										titleText: resource.thumbnailLink?.title,
									}}
									linkSection={{
										linkTo,
										externalLink,
										readMoreText:
											resource.internal?.type === ContentfulTypes.Video
												? 'View Details'
												: 'Read More',
									}}
								></ReadMore>
							);
						})}
				</MultiCarousel>
			</SectionContainer>
		</>
	);
};

type ActionType =
	| 'Search our Publications...'
	| 'Search our Research List...'
	| 'Make a Trade Enquiry'
	| 'Become a Member'
	| 'Search our Content'
	| 'See our Media Releases';

const ActionButton = (props: { action: ActionType }) => {
	const { action } = props;
	let withSearch = false;
	let linkTo = '/';
	let linkState: LinkState = {};
	switch (action) {
		case 'Search our Content':
			withSearch = true;
			linkTo = `/${LinkHelper.getPageBaseUrl(PageNames.Search)}`;
			break;
		case 'Search our Publications...':
			withSearch = true;
			linkTo = `/${LinkHelper.getPageBaseUrl(PageNames.Search)}`;
			linkState = { filters: { contentTypes: new Set([FilterContentTypeItems.Publication]) } };
			break;
		case 'Search our Research List...':
			withSearch = true;
			linkTo = `/${LinkHelper.getPageBaseUrl(PageNames.Search)}`;
			linkState = {
				filters: {
					contentTypes: new Set([FilterContentTypeItems.Project, FilterContentTypeItems.Report]),
				},
			};
			break;
		case 'Become a Member':
			linkTo = `/${LinkHelper.getPageBaseUrl(PageNames.Membership)}`;
			break;
		case 'Make a Trade Enquiry':
			linkTo = `/${LinkHelper.getPageBaseUrl(PageNames.ContactUs)}#${LinkHelper.parseInternalLink(
				'Trade enquiry'
			)}`;
			break;
		case 'See our Media Releases':
			linkTo = `/${LinkHelper.getPageBaseUrl(PageNames.Media)}`;
			break;
	}
	return (
		<CustomButton
			content={action}
			iconLeft={withSearch ? <Icons.SearchIcon scale={0.6} /> : undefined}
			variant="primary"
			linkTo={linkTo}
			linkState={linkState}
		/>
	);
};

export const HomePageDetails = (props: PageContext) => {
	const { homePage: homePageData } = props?.pageContext;

	if (!homePageData) {
		console.error('No page data for this HomePage');
		return (
			<SectionContainer withBorderTop>
				<p className={styles.message}>This page is under maintenance. Please try again later</p>
			</SectionContainer>
		);
	}

	return (
		<DefaultLayoutTemplate>
			<Helmet>
				<title>{curatedTitle(homePageData.title)}</title>
			</Helmet>
			<MainImage
				type="home"
				backgroundImageSrc={getHrImageUrl(homePageData.titleBackgroundImage?.file?.url)}
				header={homePageData.title}
				paragraph={homePageData.subtitle}
				onHowCanWeHelp={(searchTerm) => {
					navigate(`/${LinkHelper.getPageBaseUrl(PageNames.Search)}`, {
						state: {
							query: searchTerm,
						} as LinkState,
					});
				}}
			/>
			<TopSelectiveMultiCarousel pageContext={props.pageContext} />
			<SectionContainer isGrey>
				{homePageData.servicesSectionTitle && (
					<SectionContainerTitle
						text={homePageData.servicesSectionTitle}
						className={styles.servicesSectionContainer}
					/>
				)}
				<SectionContainerFlex>
					{getMoreOnLinks(homePageData.servicesSectionItems)}
				</SectionContainerFlex>
			</SectionContainer>
			<SectionContainer>
				{homePageData.animalWelfareSectionTitle && (
					<SectionContainerTitle
						text={homePageData.animalWelfareSectionTitle}
						className={styles.latestWorkSectionHeading}
					/>
				)}
				<div className={styles.latestWorkSectionPreviewWrapper}>
					<div className={styles.latestWorkSectionPreviewContainer}>
						{homePageData.animalWelfareSectionSubtitle?.animalWelfareSectionSubtitle}
					</div>
				</div>
				<div className={styles.latestWorkSectionButtonContainer}>
					<CustomButton
						content="More on Welfare"
						variant="secondary"
						iconRight={<Icons.CaretIcon direction="right" paddingPx={{ left: 30 }} />}
						linkTo="/welfare"
					/>
				</div>
			</SectionContainer>
			<div className={styles.bottomSectionContainer}>
				<div
					className={styles.bottomSectionImageDiv}
					style={{
						backgroundImage: `url(${getHrImageUrl(homePageData.bottomSectionImage?.file?.url)})`,
					}}
				></div>
				<span className={styles.bottomSectionTitle}>{homePageData.bottomSectionTitle}</span>
				<div className={styles.bottomSectionButtonsContainer}>
					{homePageData.bottomSectionButtonActions
						?.filter((action) => action)
						.map((action) => (
							<ActionButton key={action || ''} action={action as ActionType} />
						))}
				</div>
			</div>
		</DefaultLayoutTemplate>
	);
};

export default class HomePage extends React.Component<PageContext> {
	render() {
		return <HomePageDetails {...this.props} />;
	}
}
